<template>
  <b-card
    v-if="respData && $ability.can('read', this.$route.meta.resource)"
    class="blog-edit-wrapper"
  >
    <b-tabs>
      <b-tab
        v-for="(itemLang, indexLang) in language"
        :key="indexLang"
        :title="$t(itemLang.name)"
      >
        <b-form

          class="mt-2"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-overlay
                :show="loadingHtml"
                rounded="sm"
              >
                <b-form-group
                  label="Html"
                  label-for="blog-content"
                  class="mb-2"
                />
                <b-form-textarea
                  v-if="respData.html[indexLang]"
                  id="textarea-state"
                  :value="respData.html[indexLang]['value']"
                  rows="8"
                  @input="inputLanguage($event, itemLang.lang, indexLang, 'html')"
                />
              </b-overlay>

            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="saveChanges"
              >
                {{ $t('Save Changes') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="cancelBtn"
              >
                {{ $t('Cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BFormTextarea, BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import storeModule from '@/store/services/hero/slide'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// eslint-disable-next-line import/no-unresolved

const STORE_MODULE_NAME = 'slide'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BTabs,
    BTab,
    // quillEditor,
    // Editor,
    BFormTextarea,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataId: null,
      respData: {
        html: [],
      },
      loadingHtml: false,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
  },
  created() {
    const Id = this.$route.params.id // subMenu
    this.dataId = Id
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    if (this.dataId !== 'add') {
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, { id: this.dataId })
        .then(result => {
          console.log('fetch Success : ', result)
          if (result.data.data) {
            // renderBy
            this.respData = result.data.data
          }
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    inputLanguage(input, lang, index, type) {
      if (type === 'html') {
        if (this.respData.html[index].lang === lang) {
          this.respData.html[index].value = input
        }
      }
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            const obj = {
              id: this.dataId,
              html: this.respData.html,
            }
            if (this.dataId !== null && this.dataId !== 'add') {
              store
                .dispatch(`${STORE_MODULE_NAME}/updateInView`, obj)
                .then(result => {
                  this.show = false
                  console.log('fetch Success : ', result)
                }).catch(error => {
                  this.show = false
                  console.log('fetchUsers Error : ', error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
        //  else {
        //   this.showToast(
        //     'warning',
        //     'top-right',
        //     `${this.$t('DELETE_CANCEL')}!`,
        //     'AlertCircleIcon',
        //     this.$t('DELETE_CANCEL'),
        //   )
        // }
        })
    },
    cancelBtn() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

 .ql-container{
  height: 250px;
}

</style>
